import React from "react";
import Header from "../components/Header";
import AboutMe from "../components/AboutMe";
import Projects from "../components/Projects";
import Contact from "../components/Contact";
import UnderConstruction from "../components/UnderConstruction";

function HomePage() {
  return (
    <>
      <UnderConstruction />
      {/* <Header />
      <Projects />
      <AboutMe />s
      <Contact /> */}
    </>
  );
}

export default HomePage;
